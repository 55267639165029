$bg-color: #1b1b1b;
$btn-color: #272727;
$btn-hover-color: #3b3b3b;
$btn-border-color: #4d4d4d;

$accent-color: #222;
$accent-background: #2ecc40;
$light-grey: #4d4d4d;

$light-color: #fff;
